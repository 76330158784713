package de.geomobile.frontend

import com.ccfraser.muirwik.components.*
import com.ccfraser.muirwik.components.styles.Breakpoint
import com.ccfraser.muirwik.components.styles.down
import com.ccfraser.muirwik.components.styles.up
import de.geomobile.common.portalmodels.UserDTO
import de.geomobile.frontend.api.SocketState
import de.geomobile.frontend.utils.CComponent
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.css.*
import react.RBuilder
import react.RProps
import react.RState
import react.setState
import styled.StyleSheet
import styled.css
import styled.styledDiv

fun RBuilder.mainFrame() = child(MainFrame::class) { }

object GlobalStyles : StyleSheet("GlobalStyles") {
    val drawer by css {
        height = 100.vh
        width = 250.px
        position = Position.fixed
    }
    val appbar by css {
        if (PORTAL_ENV == "dev") {
            backgroundColor = Color("darkred !important")
        }
        media(currentTheme.breakpoints.up(Breakpoint.md)) {
            marginLeft = 250.px
            width = 100.pct - 250.px
        }
        media(currentTheme.breakpoints.down(Breakpoint.sm)) {
            marginLeft = 0.spacingUnits
            minWidth = 100.pct
        }
    }
    val content by css {
        media(currentTheme.breakpoints.up(Breakpoint.md)) {
            marginLeft = 250.px
            minWidth = 100.pct - 250.px
        }
        media(currentTheme.breakpoints.down(Breakpoint.sm)) {
            marginLeft = 0.spacingUnits
            minWidth = 100.pct
        }
        height = 100.pct
        background = "#eeeeee"
    }
    val card by css {
        padding(0.spacingUnits)
    }
    val cardDashboardContent by css {
        padding(2.spacingUnits)
        textAlign = TextAlign.center
    }
    val cardSameHeight by css {
        height = 100.pct
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardContent by css {
        padding(0.spacingUnits)
        paddingBottom = 1.spacingUnits
    }
    val cardListContent by css {
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardSelectListContent by css {
        padding(0.spacingUnits, 2.spacingUnits)
    }
    val cardListItemContent by css {
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardRadioContent by css {
        padding(2.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardMapContent by css {
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardBoxContent by css {
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardPaginationContent by css {
        padding(1.spacingUnits, 2.spacingUnits)
        ":last-child" { paddingBottom = 1.spacingUnits }
    }
    val cardChartContent by css {
        padding(0.spacingUnits)
        ":last-child" { paddingBottom = 0.spacingUnits }
    }
    val cardDeviceSidebarContent by css {
        padding(0.spacingUnits)
    }
}

class MainFrame : CComponent<MainFrame.Props, MainFrame.State>() {

    private var loadUserJob: Job = Job()
    private var loadInfoJob: Job = Job()

    interface Props : RProps {
    }

    class State(
        var user: UserDTO? = null,
        var loggedIn: Boolean,
        var socketDisconnected: Boolean = false,
        var companyInformation: String = "",
    ) : RState

    init {
        state = State(
            loggedIn = UserStore.token != null
        )
    }

    override fun componentDidMount() {
        UserStore.reloadListeners.add(reloadListener)
        portalWebSocketApi.socketStateObservers.add(socketStateListener)
        if (UserStore.token != null) {
            loadUser()
        }
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
        UserStore.reloadListeners.remove(reloadListener)
        portalWebSocketApi.socketStateObservers.remove(socketStateListener)
    }

    private val reloadListener: () -> Unit = {
        setState {
            this.user = UserStore.user
            this.loggedIn = UserStore.token != null
        }
    }

    private val socketStateListener: (SocketState) -> Unit = {
        setState {
            this.socketDisconnected = it == SocketState.DISCONNECTED
        }
    }

    override fun RBuilder.render() {
        document.title = "ivanto Online Portal"
        styledDiv {
            css {
                flexGrow = 1.0
                width = 100.pct
                height = 100.pct
                zIndex = 1
                position = Position.relative
                display = Display.flex
            }
            if (!state.loggedIn) {
                loggedOut(
                    onLoggedIn = {
                        setState { loggedIn = true }
                        loadUser()
                    }
                )
            } else if (state.user == null) {
                mBackdrop(
                    open = state.user == null,
                    invisible = true
                ) {
                    css { zIndex = 999999 }
                    mCircularProgress(color = MCircularProgressColor.inherit)
                }
            } else {
                loggedIn(
                    user = state.user!!,
                    onLogout = {
                        setState { loggedIn = false }
                    }
                )
            }
        }
        if (state.socketDisconnected) {
            mTypography(
                text = "Keine WebSocket-Verbindung zum Server",
                align = MTypographyAlign.center
            ) {
                css {
                    position = Position.fixed
                    bottom = 0.spacingUnits
                    width = 100.pct
                    backgroundColor = Color.darkRed
                    color = Color.white
                    zIndex = currentTheme.zIndex.drawer + 10
                }
            }
        }

        if (state.companyInformation.isNotBlank()) {
            styledDiv {
                css {
                    position = Position.fixed
                    bottom = 0.spacingUnits
                    width = 100.pct
                    minHeight = 48.px
                    backgroundColor = Color.darkGoldenrod
                    color = Color.black
                    zIndex = currentTheme.zIndex.drawer + 10
                }
                mTypography(
                    text = state.companyInformation,
                    align = MTypographyAlign.center,
                ) {
                    css {
                        margin = "0.5% 0"
                    }
                }
            }
        }
    }

    /* TODO: Clean up */
//    private fun loadCompanyInformation() {
//        loadInfoJob.cancel()
//        loadInfoJob = launch {
//            val response = withContext(Dispatchers.Default) {
//                portalRestApi.getRaw("/companyprofile/${state.user?.company}/information")
//            }
//            setState {
//                companyInformation = response
//            }
//        }
//    }

    private fun loadUser() {
        setState { user = null }

        loadUserJob.cancel()
        loadUserJob = launch {
            val response = withContext(Dispatchers.Default) {
                portalRestApi.get("/user", UserDTO.serializer())
            }

            if (UserStore.debugMode) {
                if (response.id == "DEBUG") {
                    UserStore.debugUser = response
                } else {
                    UserStore.debugMode = false
                    UserStore.debugUser = null
                    UserStore.realUser = response
                }
            } else {
                UserStore.realUser = response
            }

            val info = withContext(Dispatchers.Default) {
                try {
                    portalRestApi.getRaw("/portalsettings/information/${response.company.id}")
                } catch (ex: Exception) {
                    ""
                }
            }

            setState {
                companyInformation = info
                user = response
            }
        }
    }
}

private object ComponentStyles : StyleSheet("ComponentStyles", isStatic = false) {
    val toolbar by css {
        toolbarJsCssToPartialCss(currentTheme.mixins.toolbar)
        width = 100.pct
    }
}

fun RBuilder.spacer(expandable: Boolean = false) {
    styledDiv { css(ComponentStyles.toolbar) }
    if (expandable)
        mHidden(
            smUp = true,
            implementation = MHiddenImplementation.css
        ) {
            styledDiv { css(ComponentStyles.toolbar) }
        }
}

